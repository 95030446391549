import React from 'react';
import logo from './logo.svg';
import './App.scss';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingComponent from './components/landing/landing.component';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
function App() {

  // TODO: Add SDKs for Firebase products that you want to use
  // https://firebase.google.com/docs/web/setup#available-libraries

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: "AIzaSyADY-fphGelCF1niHVXfli5RKGgOqtB-L0",
    authDomain: "clean-climate-capital.firebaseapp.com",
    projectId: "clean-climate-capital",
    storageBucket: "clean-climate-capital.appspot.com",
    messagingSenderId: "429187878014",
    appId: "1:429187878014:web:2600247d2f41b3ab469c2f",
    measurementId: "G-XCGNYLPG3R"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  
  return (
    <div className="App">
      <BrowserRouter>


        <Routes>

          <Route path="/" element={<LandingComponent />} />


        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
