import "./landing.component.scss";
import WINDOW_IMG from "../../assets/images/section-1-window.png";
import STAR from "../../assets/images/star.png";
import STAR_WHITE from "../../assets/images/star-white.png";
import WHY_US from "../../assets/images/why-us.png";
import QUESTIONS from "../../assets/images/questions.png";
import Timeline from "../../assets/images/timeline.png";
import TEAM_1 from "../../assets/images/team-3.jpg";
import TEAM_2 from "../../assets/images/team-2.jpg";
import TEAM_3 from "../../assets/images/team-1.jpg";
import LOGO from "../../assets/images/logo.png";
import PRIVATE_DINING_IMG from "../../assets/images/private-dining-asset.png";
import CLUB_1 from "../../assets/images/club-1.png";
import CLUB_2 from "../../assets/images/club-2.png";
import CLUB_3 from "../../assets/images/club-3.png";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import UtilityService from "../../utils/utilities";
const LandingComponent = () => {



    const clickOpenPrivateDining = () => {
        UtilityService().clickSendEvent("Click Open Private Dining", "Click")
        window.open("/pdf/ccc-private-dining-event.pdf", "_blank")
    }
    const clickOpenCarbonCourse = () => {
        UtilityService().clickSendEvent("Click Open Carbon Course", "Click")
        window.open("/pdf/carbon-course.pdf", "_blank")
    }

    const clickForm = () => {
        UtilityService().clickSendEvent("Click Open Form interest", "Click")
        window.open("https://forms.gle/ibuXhwFRpbVXsbSS6", "_blank");
    }

    return (

        <>

            <div className="landing">
                <div className="landing-section-1-control">
                    <div className="header-text-control">
                        <img src={LOGO} className="header-logo" ></img>
                        <span className="header-text">Clean Climate Capital</span>
                        <Button className="landing-button-interest" onClick={clickForm}>Register your interest</Button>
                    </div>
                    <div className="container landing-section-1-container-control">
                        <div className="landing-section-1-text-control">
                            <h1 className="landing-section-1-text-header">Create a Better World <br></br>for Future Generations</h1>
                            <span className="landing-section-1-text-desc">Toward a sustainable world, Clean Climate Capital deliberately considers about projects for the planet, people, and profit (3P) as a “ triple bottom line”</span>

                        </div>
                        <img src={WINDOW_IMG} className="landing-window-img"></img>
                    </div>

                </div>
                <div className="container landing-section-2-control">
                    <span className="landing-section-2-text-40">Who We Are ...</span>
                    <span className="landing-section-2-text-lato-32">

                        Clean Climate Capital by ARC <br></br>
                        <span className="landing-section-2-text-lato-23">
                            is a regional fund focusing our investment in
                            SEA especially in Thailand and bringing cross
                            borders technology, innovation, and knowledge
                            globally to SEA as well as bringing sustainable
                            local knowledge from SEA to global.
                        </span>


                    </span>

                    <img src={STAR} className="landing-section-2-star"></img>

                    <span className="landing-section-2-text-20">
                        Established in 2015, ARC Group is a full-service financial institution
                        deeply rooted in Asia with global reach. Arc group currently owns funds
                        with US$ 700M under management.<br></br><br></br>


                        ARC connects Asia with Europe and America with offices in Hong Kong,
                        China, Singapore, Indonesia, Malaysia, Vietnam, India, Thailand and
                        UAE, bridging connection, innovation, and knowledge across the world.<br></br><br></br>


                        ARC expertise in IPOs, M&A, buy-out, distress assets and capital raise
                        also help to provide the best exit and return to our limited partners.

                    </span>
                </div>

                <div className="landing-section-3-control">
                    <span className="landing-section-3-text-lato-32">

                        “We are the first scalable fund in Thailand with unique<br></br>
                        investment strategy, merging impact and land investments<br></br>
                        for sustainable growth and return”
                    </span>

                </div>

                <div className="container landing-section-4-control">
                    <img src={QUESTIONS} className="landing-section-4-question"></img>
                    <img src={QUESTIONS} className="landing-section-4-question-2"></img>
                    <span className="landing-section-4-text-header">
                        “Why us”
                    </span>
                    <span className="landing-section-4-text-desc">

                        We have an In-house advisory team <br></br>
                        globally which can bring many <br></br>
                        expertises and technologies around the <br></br>
                        world to create synergy and strong <br></br>
                        connection for further development.<br></br>
                    </span>

                    <div className="landing-section-4-grid-control">
                        <img src={WHY_US} className="landing-section-4-why-us-img"></img>

                        <div className="landing-section-4-grid-right-control">
                            <div className="landing-section-4-content-control">
                                <span className="landing-section-4-content-header">Impact Investment</span>
                                <span className="landing-section-4-content-desc">Prioritize the projects that provide proven benefits to the environment, society, and community, measured by reliable methods</span>
                            </div>
                            <div className="landing-section-4-content-control">
                                <span className="landing-section-4-content-header">Asset Investment (Land)</span>
                                <span className="landing-section-4-content-desc">Long-term investment hedged against inflation exhibits the records of approximately double growth in valuation value over 10 years</span>
                            </div>
                            <div className="landing-section-4-content-control">
                                <span className="landing-section-4-content-header">Scalability</span>
                                <span className="landing-section-4-content-desc">Focus investing in the deals that allow us to create the economy of scale in operation, leading us to 20%+ expected annual return from the investment</span>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="landing-section-5-control">
                    <div className="container landing-section-5-container">
                        <img src={STAR_WHITE} className="landing-section-5-star"></img>
                        <span className="landing-section-5-timeline">Timeline</span>
                        <img src={Timeline} className="landing-section-5-timeline-img"></img>
                        <span className="landing-section-5-timeline">Team</span>
                        <div className="landing-section-5-team-grid">
                            <div className="landing-section-5-team-item">
                                <img src={TEAM_1} className="landing-section-5-team-img"></img>
                                <div className="landing-section-5-name-control">

                                    <span className="landing-section-5-name-header">Boonrod Yaowapruek</span>
                                    <span className="landing-section-5-name-desc">General Partner</span>

                                    <span className="landing-section-5-name-desc-2">Over 20 years of being a
                                        professional in climate finance
                                        and environment and clean
                                        energy sector</span>
                                </div>
                            </div>
                            <div className="landing-section-5-team-item">
                                <img src={TEAM_2} className="landing-section-5-team-img"></img>
                                <div className="landing-section-5-name-control">
                                    <span className="landing-section-5-name-header">Teerus Boon-Long</span>
                                    <span className="landing-section-5-name-desc">General Partner</span>
                                    <span className="landing-section-5-name-desc-2">20 years of experience in
                                        real estate Private Equity and
                                        Venture Capital in SEA</span>
                                </div>
                            </div>
                            <div className="landing-section-5-team-item">
                                <img src={TEAM_3} className="landing-section-5-team-img"></img>
                                <div className="landing-section-5-name-control">
                                    <span className="landing-section-5-name-header">Tom Vachiramon</span>
                                    <span className="landing-section-5-name-desc">General Partner</span>
                                    <span className="landing-section-5-name-desc-2">Technologist with 20 years of
                                        experience in well-known
                                        tech companies</span>
                                </div>
                            </div>


                        </div>

                    </div>

                </div>
                <div className="container landing-section-6-control">

                    <img src={PRIVATE_DINING_IMG} className="landing-section-6-img"></img>
                    <div className="landing-section-6-right-item">
                        <span className="landing-section-6-header">Interested in learning</span>
                        <span className="landing-section-6-desc">more about us? Please contact us <span className="link" onClick={clickForm}>here</span>.</span>
                        <Button className="landing-section-6-button" onClick={clickOpenPrivateDining}>More Detail <FontAwesomeIcon className="icon" icon={faChevronRight} /></Button>
                    </div>
                </div>
                <div className="landing-section-7-control">
                    <img src={CLUB_1} className="landing-section-7-club-1"></img>
                    <img src={CLUB_2} className="landing-section-7-club-2"></img>
                    <img src={CLUB_3} className="landing-section-7-club-3"></img>
                    <div className="container landing-section-7-main">

                        <img src={LOGO} className="landing-section-7-logo"></img>
                        <span className="landing-section-7-header">CCC Club & Course</span>
                        <span className="landing-section-7-sub-header">Cracking the Sustainability Code: From Confusion to Clear Clean Action</span>
                        <span className="landing-section-7-desc">A well-structured executive course on sustainability and climate change helps address these misconceptions and equips leaders with the knowledge, skills, and tools to navigate the environmental landscape effectively, make sound decisions, and drive positive change within their organizations.</span>
                        <Button className="landing-section-7-button" onClick={clickOpenCarbonCourse}>More Detail <FontAwesomeIcon className="icon" icon={faChevronRight} /></Button>
                        <span className="landing-link-interest-white">Interest CCC Club & Course? <span className="link" onClick={clickForm}>Click here</span></span>
                    </div>
                    <div className="divider-landing"></div>
                    <div className="footer-text-control">
                        <img src={LOGO} className="footer-logo" ></img>
                        <span className="footer-text">Clean Climate Capital</span>
                    </div>
                </div>

            </div>
        </>
    )
}

export default LandingComponent;