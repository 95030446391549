const local = {
    ga_4: "G-1EJGGDSHEQ",
    ga: ""
};

const test = {
    ga_4: "G-1EJGGDSHEQ",
    ga: ""
};
const prod = {
    ga_4: "G-PRX4QLP3MJ",
    ga: ""
   
};

const config = 
    process.env.REACT_APP_ENVIRONMENT === 'production' ? prod : 
    process.env.REACT_APP_ENVIRONMENT === 'local' ? local 
        : test;

export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
};